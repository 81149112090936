import * as React from "react";

import Footer from "../footer/Footer";

import Commoncard from "../commoncomponents/Commoncard";
import Navbar from "../components/Navbar";

function Homepage() {

  
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Navbar />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Commoncard />
      </div>
      <Footer />
    </div>
  );
}

export default Homepage;
