import React from "react";
import Dashboardmenubar from "../screen/Dashboardmenubar";

function Dashboard() {
  return (
    <div>
      <Dashboardmenubar />
    </div>
  );
}

export default Dashboard;
