// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.box {
  height: 130px;
  width: 230px;
  border-radius: 20px;
  box-shadow: 3px 3px 10px #001e57c0;
  background-color: #3f0097;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}
.box:hover {
  transform: scale(1.08);
}
.topic-heading {
  color: #fff;
}
.topic {
  color: #fff;
}

.parent-div {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
}

.child-div {
  flex: 1 1 45%;
  border-radius: 5px;
  margin: 10px;
  background-color: #fff;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}
/* .child-divone {
  flex: 1 1 45%;
  color: blue;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  margin: 10px;
  border-radius: 5px;
  background-color: red;
} */

@media (max-width: 600px) {
  .parent-div {
    flex-direction: column;
  }

  .child-div {
    flex: 1 1 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Menutabs/Userhome.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,mBAAmB;EACnB,kCAAkC;EAClC,yBAAyB;EACzB,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,6BAA6B;EAC7B,eAAe;EACf,sCAAsC;AACxC;AACA;EACE,sBAAsB;AACxB;AACA;EACE,WAAW;AACb;AACA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,eAAe;EACf,8BAA8B;EAC9B,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,YAAY;EACZ,sBAAsB;EACtB,0CAA0C;AAC5C;AACA;;;;;;;GAOG;;AAEH;EACE;IACE,sBAAsB;EACxB;;EAEA;IACE,cAAc;EAChB;AACF","sourcesContent":[".box {\n  height: 130px;\n  width: 230px;\n  border-radius: 20px;\n  box-shadow: 3px 3px 10px #001e57c0;\n  background-color: #3f0097;\n  padding: 20px;\n  display: flex;\n  align-items: center;\n  justify-content: space-around;\n  cursor: pointer;\n  transition: transform 0.3s ease-in-out;\n}\n.box:hover {\n  transform: scale(1.08);\n}\n.topic-heading {\n  color: #fff;\n}\n.topic {\n  color: #fff;\n}\n\n.parent-div {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n  margin-top: 20px;\n}\n\n.child-div {\n  flex: 1 1 45%;\n  border-radius: 5px;\n  margin: 10px;\n  background-color: #fff;\n  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);\n}\n/* .child-divone {\n  flex: 1 1 45%;\n  color: blue;\n  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);\n  margin: 10px;\n  border-radius: 5px;\n  background-color: red;\n} */\n\n@media (max-width: 600px) {\n  .parent-div {\n    flex-direction: column;\n  }\n\n  .child-div {\n    flex: 1 1 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
