import React from "react";

function UserQrupload() {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <h3>UserQrUpload</h3>

      <div style={{ flex: "1 1 100%", minWidth: "200px", marginTop: "20px" }}>
        <label
          style={{ display: "block", marginBottom: "8px", fontWeight: "500" }}
        >
          Upload QR Code
        </label>
        <input
          type="file"
          accept="image/*" // Limits the upload to image files
          style={{
            width: "60%",
            borderRadius: "10px",
            padding: "10px",
            border: "1px solid #ccc",
            //   borderRadius: "5px",
          }}
        />
      </div>

      <img
        style={{ width: "200px", height: "200px", marginTop: "20px" }}
        src="https://cdn.pixabay.com/photo/2013/07/12/14/45/qr-code-148732_1280.png"
      />

      <button
        style={{
          width: "100px",
          padding: "5px",
          backgroundColor: "red",
          color: "#fff",
          marginTop: "20px",
          borderRadius: "10px",
          borderColor: "red",
        }}
      >
        Submit
      </button>
    </div>
  );
}

export default UserQrupload;
