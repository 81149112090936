import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import MemberlistUser from "./MemberlistUser";

const columns = [
  { id: "sno", label: "S.No", minWidth: 50 },
  { id: "memberId", label: "Member ID", minWidth: 100 },
  { id: "memberName", label: "Member Name", minWidth: 100 },
  { id: "mobile", label: "Mobile", minWidth: 100 },
  { id: "package", label: "Package", minWidth: 100 },
  { id: "memberType", label: "Member Type", minWidth: 100 },
  { id: "owner", label: "Owner", minWidth: 100 },
  { id: "detail", label: "Detail", minWidth: 100 },
  { id: "accountStatus", label: "Account Status", minWidth: 100 },
  { id: "recharge", label: "Recharge", minWidth: 100 },
  { id: "expressPayout", label: "Express Payout", minWidth: 100 },
  { id: "expressDmr", label: "Express DMR", minWidth: 100 },
  { id: "dmr", label: "DMR", minWidth: 100 },
  { id: "aeps", label: "AEPS", minWidth: 100 },
  { id: "payout", label: "Payout", minWidth: 100 },
  { id: "uti", label: "UTI", minWidth: 100 },
  { id: "bbps", label: "BBPS", minWidth: 100 },
  { id: "zupi", label: "ZUPI", minWidth: 100 },
  { id: "zpayout", label: "ZPayout", minWidth: 100 },
  { id: "bankDetail", label: "Bank Detail", minWidth: 150 },
  { id: "bankVerify", label: "Bank Verify", minWidth: 150 }, // Bank Verify column
  { id: "edit", label: "Edit", minWidth: 100 }, // Edit column
];

function createData(
  sno,
  memberId,
  memberName,
  mobile,
  packageData,
  memberType,
  owner,
  debit,
  credit,
  balance
) {
  return {
    sno,
    memberId,
    memberName,
    mobile,
    packageData,
    memberType,
    owner,
    debit,
    credit,
    balance,
  };
}

const rows = [
  createData(
    1,
    "M001",
    "John Doe",
    "1234567890",
    "Gold",
    "Premium",
    "Alice",
    500,
    1000,
    500
  ),
  createData(
    2,
    "M002",
    "Jane Smith",
    "0987654321",
    "Silver",
    "Standard",
    "Bob",
    300,
    800,
    500
  ),
  createData(
    3,
    "M003",
    "Alice Johnson",
    "5556667777",
    "Platinum",
    "Premium",
    "Carol",
    200,
    400,
    200
  ),
  createData(
    4,
    "M004",
    "Michael Brown",
    "4445556666",
    "Gold",
    "Basic",
    "David",
    100,
    600,
    500
  ),
  createData(
    5,
    "M005",
    "Emily Davis",
    "3334445555",
    "Silver",
    "Standard",
    "Eve",
    400,
    700,
    300
  ),
];

function Reportscreen() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showTable, setShowTable] = useState(true);
  const [selectedMember, setSelectedMember] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleViewClick = (row) => {
    setSelectedMember(row);
    setShowTable(false);
  };

  const handleBackClick = () => {
    setShowTable(true);
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "40px",
        backgroundColor: "#fff",
        boxShadow: "2px 2px 5px 1px rgba(0, 0, 0, 0.5)",
      }}
    >
      {showTable ? (
        <>
          <h4 style={{ fontWeight: "600", fontSize: "20px" }}>
            View All Member Report
          </h4>
          <div style={{ display: "flex", marginTop: "30px" }}>
            <input
              placeholder="Search"
              style={{
                width: "40%",
                height: "45px",
                borderRadius: "10px",
                borderColor: "lightGray",
                paddingLeft: "20px",
              }}
            />
          </div>
          <Paper sx={{ width: "100%", overflow: "hidden", marginTop: "60px" }}>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          fontWeight: "bold",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.memberId}
                      >
                        {columns.map((column) => {
                          if (column.id === "sno") {
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {index + 1 + page * rowsPerPage}
                              </TableCell>
                            );
                          } else if (column.id === "detail") {
                            return (
                              <TableCell key={column.id} align={column.align}>
                                <Button
                                  variant="contained"
                                  size="small"
                                  style={{
                                    borderColor: "red",
                                    backgroundColor: "#fff",
                                    color: "green",
                                    borderWidth: "5px",
                                  }}
                                  onClick={() => handleViewClick(row)}
                                >
                                  View
                                </Button>
                              </TableCell>
                            );
                          } else if (
                            column.id === "accountStatus" ||
                            column.id === "recharge" ||
                            column.id === "expressPayout" ||
                            column.id === "expressDmr" ||
                            column.id === "dmr" ||
                            column.id === "aeps" ||
                            column.id === "payout" ||
                            column.id === "uti" ||
                            column.id === "bbps" ||
                            column.id === "zupi" ||
                            column.id === "zpayout" ||
                            column.id === "bankVerify"
                          ) {
                            return (
                              <TableCell key={column.id} align={column.align}>
                                <Button
                                  variant="contained"
                                  size="small"
                                  style={{
                                    backgroundColor: "red",
                                    color: "white",
                                    width: "100px",
                                    borderRadius: "20px",
                                    height: "40px",
                                  }}
                                >
                                  Off
                                </Button>
                              </TableCell>
                            );
                          } else if (column.id === "bankDetail") {
                            return (
                              <TableCell key={column.id} align={column.align}>
                                <Button
                                  variant="contained"
                                  size="small"
                                  style={{
                                    fontSize: "12px",
                                    backgroundColor: "red",
                                    color: "white",
                                    width: "150px",
                                    borderRadius: "20px",
                                    height: "40px",
                                  }}
                                >
                                  View Bank Detail
                                </Button>
                              </TableCell>
                            );
                          } else if (column.id === "edit") {
                            return (
                              <TableCell key={column.id} align={column.align}>
                                <Button
                                  variant="contained"
                                  size="small"
                                  style={{
                                    fontSize: "11px",
                                    backgroundColor: "red",
                                    color: "white",
                                    width: "80px",
                                    borderRadius: "20px",
                                    height: "40px",
                                  }}
                                >
                                  Edit
                                </Button>
                              </TableCell>
                            );
                          } else {
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {row[column.id]}
                              </TableCell>
                            );
                          }
                        })}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </>
      ) : (
        <MemberlistUser
          memberId={selectedMember.memberId}
          debit={selectedMember.debit}
          credit={selectedMember.credit}
          balance={selectedMember.balance}
          onBackClick={handleBackClick}
        />
      )}
    </div>
  );
}

export default Reportscreen;
