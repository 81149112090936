import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { format } from "date-fns";

const columns = [
  { id: "sno", label: "S.No", minWidth: 50 },
  { id: "memberId", label: "Member ID", minWidth: 100 },
  { id: "date", label: "Date", minWidth: 100 },
  { id: "narration", label: "Narration", minWidth: 100 },
  { id: "credit", label: "Credit", minWidth: 100 },
  { id: "debit", label: "Debit", minWidth: 100 },
  { id: "balance", label: "Balance", minWidth: 100 },
];

function createData(sno, memberId, date, narration, credit, debit, balance) {
  return { sno, memberId, date, narration, credit, debit, balance };
}

// Sample data for the table
const rows = [
  createData(1, "M001", "2024-09-01", "Monthly deposit", 500, 0, 500),
  createData(2, "M002", "2024-09-02", "Withdrawal", 0, 200, 300),
  createData(3, "M003", "2024-09-03", "Fund Transfer", 0, 150, 150),
  createData(4, "M004", "2024-09-04", "Monthly deposit", 1000, 0, 1150),
];

const MemberlistUser = ({ memberId, debit, credit, balance, onBackClick }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // Filter rows based on selected memberId
  const filteredRows = rows.filter(row => row.memberId === memberId);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
      <h4 style={{ fontWeight: "500", marginTop: "30px", paddingLeft: "20px" }}>
        Member E Wallet Balance
      </h4>
      <div
        style={{
          display: "flex",
          marginTop: "30px",
          alignItems: "center",
          padding: "10px",
          justifyContent: "center",
          gap: 30,
        }}
      >
        <DatePicker
          selected={fromDate}
          onChange={(date) => setFromDate(date)}
          dateFormat="yyyy/MM/dd"
          isClearable
          placeholderText="From date"
          customInput={<TextField label="From date" variant="outlined" />}
        />
        <DatePicker
          selected={toDate}
          onChange={(date) => setToDate(date)}
          dateFormat="yyyy/MM/dd"
          isClearable
          placeholderText="To date"
          customInput={<TextField label="To date" variant="outlined" />}
        />
        <Button
          variant="contained"
          color="primary"
          style={{
            marginLeft: "10px",
            height: "56px",
            backgroundColor: "red",
            borderRadius: "10px",
          }}
        >
          Search
        </Button>
      </div>

      <div
        style={{
          width: "90%",
          display: "flex",
          flexDirection: "column",
          marginTop: "30px",
        }}
      >
        <span style={{ paddingLeft: "36px", color: "gray", fontWeight: "500" }}>
          Search
        </span>
        <div style={{ display: "flex", marginLeft: "30px" }}>
          <input
            style={{
              width: "100%",
              height: "45px",
              borderRadius: "15px",
              borderColor: "lightGray",
              paddingLeft: "20px",
            }}
            placeholder="Search"
          />
        </div>
      </div>

      <Paper sx={{ width: "100%", overflow: "hidden", marginTop: "20px" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredRows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export default MemberlistUser;
