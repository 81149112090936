import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";

const columns = [
  { id: "sno", label: "S.No", minWidth: 50 },
  { id: "memberId", label: "Member ID", minWidth: 100 },
  { id: "memberName", label: "Member Name", minWidth: 170 },
  { id: "mobile", label: "Mobile", minWidth: 130 },
  { id: "debit", label: "Debit", minWidth: 100, align: "right" },
  { id: "credit", label: "Credit", minWidth: 100, align: "right" },
  { id: "balance", label: "Balance", minWidth: 100, align: "right" },
  { id: "detail", label: "Detail", minWidth: 100 },
];

function createData(sno, memberId, memberName, mobile, debit, credit, balance) {
  return { sno, memberId, memberName, mobile, debit, credit, balance };
}

const rows = [
  createData(1, "M001", "John Doe", "1234567890", 500, 1000, 500),
  createData(2, "M002", "Jane Smith", "0987654321", 300, 800, 500),
  createData(3, "M003", "Alice Johnson", "5556667777", 200, 400, 200),
  createData(4, "M004", "Michael Brown", "4445556666", 100, 600, 500),
  createData(5, "M005", "Emily Davis", "3334445555", 400, 700, 300),
  createData(6, "M006", "David Wilson", "2223334444", 600, 900, 300),
  createData(7, "M007", "Sophia Martinez", "1112223333", 250, 500, 250),
  createData(8, "M008", "James Anderson", "8889990000", 350, 700, 350),
  createData(9, "M009", "Isabella Thomas", "7778889999", 450, 800, 350),
  createData(10, "M010", "Oliver Jackson", "6667778888", 150, 400, 250),
];

const Ewallet = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#fff",
        boxShadow: "2px 2px 5px 1px rgba(0, 0, 0, 0.5)",
        gap: 20,
      }}
    >
      <h1
        style={{
          fontSize: "20px",
          fontWeight: "500",
          paddingLeft: "20px",
          marginTop: "20px",
        }}
      >
        Member E Wallet Balance
      </h1>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <input
          style={{
            width: "93%",
            height: "45px",
            borderRadius: "15px",
            borderColor: "lightGray",
            paddingLeft:'20px'
          }}
          placeholder="Search"
        />
      </div>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    //   style={{fontWeight:"bold", color:"red" }}
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, fontWeight: "bold" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.memberId}
                  >
                    {columns.map((column) => {
                      if (column.id === "sno") {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {index + 1 + page * rowsPerPage}
                          </TableCell>
                        );
                      } else if (column.id === "detail") {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            <Button
                              variant="contained"
                              size="small"
                              style={{ backgroundColor: "#90EE90" }}
                            >
                              View
                            </Button>
                          </TableCell>
                        );
                      } else {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {row[column.id]}
                          </TableCell>
                        );
                      }
                    })}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export default Ewallet;
