import React from "react";
import "./Addmoney.css";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Useraccountdetail from "../components/Useraccountdetail";
import Qrcode from "../assets/qrcode.jpg";

const Addmoney = () => {
  return (
    <Container>
      <Box my={4}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} className="child-div">
            <img src={Qrcode} className="qrcode-img" alt="qrcode" />
          </Grid>
          <Grid item xs={12} sm={6} className="child-divone">
            <Useraccountdetail />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Addmoney;
