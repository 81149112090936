import * as React from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import "./Userhome.css";
import Dashboardcard from "../commoncomponents/Dashboardcard";
import Usercards from "../commoncomponents/Usercards";
import Earningcontainer from "../commoncomponents/Earningcontainer";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function Userdashboardmenu() {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={{ xs: 2, md: 3 }}>
          <Grid xs={12} sm={6} md={4}>
            <Dashboardcard />
          </Grid>

          <Grid xs={12} sm={6} md={4}>
            <Dashboardcard />
          </Grid>

          <Grid xs={12} sm={6} md={4}>
            <Dashboardcard />
          </Grid>
        </Grid>
      </Box>

      <div className="parent-div">
        <div className="child-div">
          <Container>
            <Box my={4}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Paper
                    style={{ borderRadius: "10px", paddingLeft: "17px" }}
                    elevation={3}

                    // style={{ padding: "20px", textAlign: "center" }}
                  >
                    <Usercards />
                    <br></br>
                    <br></br>
                    <br></br>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Paper
                    elevation={3}
                    style={{ borderRadius: "10px", paddingLeft: "17px" }}
                    // style={{ padding: "20px", textAlign: "center" }}
                  >
                    <Usercards
                      text={"AEPS Balance"}
                      textsmall={"0.00"}
                      style={{ color: "green", fontWeight: "bold" }}
                    />
                    <br></br>
                    <br></br>
                    <br></br>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Paper
                    elevation={3}
                    style={{ borderRadius: "10px", paddingLeft: "17px" }}
                    // style={{ padding: "20px", textAlign: "center" }}
                  >
                    <Usercards
                      text={"Today AEPS Transaction"}
                      textsmall={"0.00"}
                      style={{ color: "red", fontWeight: "bold" }}
                    />
                    <br></br>
                    <br></br>
                    <br></br>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Paper
                    elevation={3}
                    style={{ borderRadius: "10px", paddingLeft: "17px" }}
                    // style={{ padding: "20px", textAlign: "center" }}
                  >
                    <Usercards
                      text={"Today DMR Transaction"}
                      textsmall={"0.00"}
                      style={{ color: "sky", fontWeight: "bold" }}
                    />
                    <br></br>
                    <br></br>
                    <br></br>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </div>
        <Earningcontainer />
      </div>
    </div>
  );
}

export default Userdashboardmenu;
