import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


export const getAllData = createAsyncThunk(
  "Items/getAllData",
  async (args, { rejectWithValue }) => {
    try {

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/admin/dashboard/summary`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjIsImVtYWlsIjoiYWRtaW5AemV5b3BheS5vbmxpbmUiLCJyb2xlIjoiQURNSU4iLCJpYXQiOjE3MjU2OTgyNTIsImV4cCI6MTcyNjMwMzA1Mn0.sE1PJhNOfz03QnBrRcp04Y3v6nSu2_UHMoNxA-CQEBs`, // Correct format for Authorization header
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Network issue ${response.status}`);
      }

      const data = await response.json(); // Assuming the API returns JSON
      return data; // Return the data to be used in the component
    } catch (error) {
      console.error("Fetch error:", error);
      return rejectWithValue(error.message); // Provide detailed error information
    }
  }
);

export const Adminuserdata = createSlice({
  name: "Items",
  initialState: {
    users: [],
    loading: false,
    error: null,
  },
  reducers: [],
  extraReducers: (builder) => {
    builder
      .addCase(getAllData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllData.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload; // Ensure the data structure matches your API response
      })
      .addCase(getAllData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Accessing error message from action.payload
      });
  },
});

export default Adminuserdata.reducer;
