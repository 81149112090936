import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import MemberlistUser from "./MemberlistUser";

const columns = [
  { id: "sno", label: "S.No", minWidth: 50 },
  { id: "memberId", label: "Member ID", minWidth: 100 },
  { id: "memberName", label: "Member Name", minWidth: 100 },
  { id: "mobile", label: "Mobile", minWidth: 100 },
  { id: "package", label: "Package", minWidth: 100 },
  { id: "memberType", label: "Member Type", minWidth: 100 },
  { id: "owner", label: "Owner", minWidth: 100 },
  { id: "debit", label: "Debit", minWidth: 100, align: "right" },
  { id: "credit", label: "Credit", minWidth: 100, align: "right" },
  { id: "balance", label: "Balance", minWidth: 100, align: "right" },
  { id: "detail", label: "Detail", minWidth: 100 },
  { id: "accountStatus", label: "Account Status", minWidth: 100 }, // New column
  { id: "recharge", label: "Recharge", minWidth: 100 }, // New column
 
];

function createData(
  sno,
  memberId,
  memberName,
  mobile,
  packageData,
  memberType,
  owner,
  debit,
  credit,
  balance
) {
  return {
    sno,
    memberId,
    memberName,
    mobile,
    packageData,
    memberType,
    owner,
    debit,
    credit,
    balance,
  };
}

const rows = [
  createData(1, "M001", "John Doe", "1234567890", "Gold", "Premium", "Alice", 500, 1000, 500),
  createData(2, "M002", "Jane Smith", "0987654321", "Silver", "Standard", "Bob", 300, 800, 500),
  createData(3, "M003", "Alice Johnson", "5556667777", "Platinum", "Premium", "Carol", 200, 400, 200),
  createData(4, "M004", "Michael Brown", "4445556666", "Gold", "Basic", "David", 100, 600, 500),
  createData(5, "M005", "Emily Davis", "3334445555", "Silver", "Standard", "Eve", 400, 700, 300),
];

const Memberlist = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showTable, setShowTable] = useState(true);
  const [selectedMember, setSelectedMember] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleViewClick = (row) => {
    setSelectedMember(row);
    setShowTable(false);
  };

  const handleBackClick = () => {
    setShowTable(true);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#fff",
        boxShadow: "2px 2px 5px 1px rgba(0, 0, 0, 0.5)",
      }}
    >
      {showTable ? (
        <>
          <span style={{ paddingLeft: "20px", color: "gray", fontWeight: "bold" }}>
            Member E Wallet Balance
          </span>

          <div style={{ display: "flex", flexDirection: "column", marginTop: "30px" }}>
            <span style={{ paddingLeft: "30px", color: "gray", fontWeight: "500" }}>Search</span>
            <div style={{ display: "flex", marginLeft: "30px" }}>
              <input
                style={{
                  width: "80%",
                  height: "45px",
                  borderRadius: "15px",
                  borderColor: "lightGray",
                  paddingLeft: "20px",
                }}
                placeholder="Search"
              />
            </div>
          </div>

          <Paper sx={{ width: "100%", overflow: "hidden", marginTop: "60px" }}>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          fontWeight: "bold",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.memberId}>
                      {columns.map((column) => {
                        if (column.id === "sno") {
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {index + 1 + page * rowsPerPage}
                            </TableCell>
                          );
                        } else if (column.id === "detail") {
                          return (
                            <TableCell key={column.id} align={column.align}>
                              <Button
                                variant="contained"
                                size="small"
                                style={{
                                  borderColor: "red",
                                  backgroundColor: "#fff",
                                  color: "green",
                                  borderWidth: "5px",
                                }}
                                onClick={() => handleViewClick(row)}
                              >
                                View
                              </Button>
                            </TableCell>
                          );
                        } else if (column.id === "accountStatus") {
                          return (
                            <TableCell key={column.id} align={column.align}>
                            <Button
                            variant="contained"
                            size="small"
                            style={{
                              backgroundColor: "red",
                              color: "white",
                              width: "130px",
                              borderRadius: "20px",
                              height: "40px",
                            }}
                          >
                            Active
                          </Button>
                            </TableCell>
                          );
                        } else if (column.id === "recharge") {
                          return (
                            <TableCell key={column.id} align={column.align}>
                            <Button
                            variant="contained"
                            size="small"
                            style={{
                              backgroundColor: "red",
                              color: "white",
                              width: "130px",
                              borderRadius: "20px",
                              height: "40px",
                            }}
                          >
                            Recharge
                          </Button>
                            </TableCell>
                          );
                        } else {
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {row[column.id]}
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </>
      ) : (
        <MemberlistUser
          memberId={selectedMember.memberId}
          debit={selectedMember.debit}
          credit={selectedMember.credit}
          balance={selectedMember.balance}
          onBackClick={handleBackClick}
        />
      )}
    </div>
  );
};

export default Memberlist;
