import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Userpage from "../Menutabs/Userpage";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import Userdashboardmenu from "../Menutabs/Userdashboardmenu";
import Logo from "../assets/logo.png";
import Commonbutton from "../commoncomponents/Commonbutton";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import ChatIcon from "@mui/icons-material/Chat";
import InputIcon from "@mui/icons-material/Input";
import HomeIcon from "@mui/icons-material/Home";
import Addmoney from "../Menutabs/Addmoney";
import { useNavigate } from "react-router-dom";
import Wallet from "../pages/Wallet";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import Accordion from "@mui/material/Accordion";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import "./Menu.css";
import { useSelector, useDispatch } from "react-redux";
import { getAllData } from "../redux/Balance";

import clsx from "clsx";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

function Dashboardmenubar() {
  const [expanded, setExpanded] = useState(false);
  const [selectedtab, setSelectedtab] = useState(0);
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const openicon = Boolean(anchorEl);

  const { users } = useSelector((state) => state.Balance);

  console.log(users.balance, "userbalance");
  const Balance = users.balance

  localStorage.setItem("Balance", Balance)

  // call baalnce api

  useEffect(() => {
    dispatch(getAllData());
  }, [dispatch]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // menu user icon
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  //logout button

  const handleClose = () => {
    navigate("/");
    setAnchorEl(null);
  };

  // menu button

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  // logout button
  const handleLogout = () => {
    localStorage.removeItem("authToken"); // Clear the token from local storage
    navigate("/"); // Redirect to login page
    setAnchorEl(null); // Close the menu
  };

  const ExpandIcon = styled(ExpandMoreIcon)(({ theme, expanded }) => ({
    transform: expanded ? "rotate(0deg)" : "rotate(-90deg)",
    transition: "transform 0.3s",
  }));

  const handleChange = () => {
    setExpanded(!expanded);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <AppBar position="fixed" open={open} style={{ backgroundColor: "#fff" }}>
        <Toolbar
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              // marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon style={{ color: "black" }} />
          </IconButton>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >

          <Box sx={{ flexGrow: 1 }} />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "9px",
                textAlign: "center",
                marginLeft: "auto",
              }}
            >
              <Commonbutton
                onClick={() => {
                  setSelectedtab(2);
                }}
              />

              <button
                style={{
                  backgroundColor: "#F7CAC9",
                  padding: "10px",
                  borderColor: "transparent",
                  color: "lightslategrey",
                  fontFamily: "sans-serif",
                  fontWeight: "bold",
                }}
              >
                {`${localStorage.getItem("UserId")}`}
              </button>

              <button
                style={{
                  backgroundColor: "#F7CAC9",
                  padding: "10px",
                  borderColor: "transparent",
                  color: "lightslategrey",
                  fontFamily: "sans-serif",
                  fontWeight: "bold",
                }}
              >
                AEPS Balance
              </button>
              <button
                style={{
                  backgroundColor: "#F7CAC9",
                  padding: "10px",
                  borderColor: "transparent",
                  color: "lightslategrey",
                  fontFamily: "sans-serif",
                  fontWeight: "bold",
                }}
              >
                EBalance <br></br> {users.balance} {users.currency}
              </button>

              <Tooltip title="Account settings">
                <IconButton
                  onClick={handleClick}
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <Avatar sx={{ width: 32, height: 32 }}>M</Avatar>
                </IconButton>
              </Tooltip>
            </Box>

            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={openicon}
              onClose={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&::before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
            >
              <MenuItem onClick={handleClose}>
                <Avatar /> Profile
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Avatar /> My account
              </MenuItem>
              <Divider />
              <MenuItem onClick={handleClose}>
                <ListItemIcon>
                  <PersonAdd fontSize="small" />
                </ListItemIcon>
                Add another account
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <ListItemIcon>
                  <Settings fontSize="small" />
                </ListItemIcon>
                Settings
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70px",
            }}
          >
            <img src={Logo} style={{ width: "70%" }} alt="company logo" />
          </div>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />

        {open && (
          <>
            <List style={{ backgroundColor: "#3f0097" }}>
              <ListItem>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Avatar
                    alt="Cindy Baker"
                    src="/static/images/avatar/3.jpg"
                    style={{ width: "70px", height: "70px" }}
                  />
                  <div
                    style={{
                      marginTop: "10px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ color: "#AAADB7", fontWeight: "bold" }}>
                      {localStorage.getItem("Name")}
                    </span>
                    <span style={{ color: "#AAADB7", fontSize: "13px" }}>
                      Retailer
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "20px",
                      marginTop: "10px",
                    }}
                  >
                    <PersonOutlineIcon
                      sx={{ fontSize: 30, color: "#AAADB7" }}
                    />
                    <MailOutlineIcon sx={{ fontSize: 30, color: "#AAADB7" }} />
                    <ChatIcon sx={{ fontSize: 30, color: "#AAADB7" }} />
                    <InputIcon
                      onClick={handleLogout}
                      sx={{ fontSize: 30, color: "#AAADB7" }}
                    />
                  </div>
                </div>
              </ListItem>
            </List>
            <Divider />
          </>
        )}

        <List style={{ backgroundColor: "#3f0097", height: "100vh" }}>
          <ListItem
            disablePadding
            sx={{
              display: "block",
              "&:hover": {
                backgroundColor: "yellow",
              },
            }}
            style={{ borderRadius: "10px" }}
          >
            <ListItemButton
              onClick={() => setSelectedtab(0)}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <HomeIcon sx={{ fontSize: 30, color: "#AAADB7" }} />
              </ListItemIcon>
              <ListItemText
                style={{ fontSize: "20px", color: "#B4B6B8" }}
                primary={"Dashboard"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>

          <ListItem
            disablePadding
            sx={{
              display: "block",
              "&:hover": {
                backgroundColor: "yellow",
              },
              width: open ? "auto" : "60px",
              transition: "width 0.3s",
            }}
            style={{ borderRadius: "10px" }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <BusinessCenterIcon  onClick={() => setSelectedtab(3)} sx={{ fontSize: 30, color: "#AAADB7" }} />
              </ListItemIcon>
              <Accordion
                expanded={expanded}
                onChange={handleChange}
                sx={{
                  width: "100%",
                  bgcolor: "#3f0097",
                  // borderRadius: '10px',
                  display: open ? "block" : "none",
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{
                        color: "white",
                        transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
                        transition: "transform 0.3s",
                      }}
                    />
                  }
                  aria-controls="panel2-content"
                  id="panel2-header"
                  sx={{
                    bgcolor: "#3f0097",
                  }}
                >
                  <ListItemText
                    style={{
                      fontSize: "20px",
                      color: "#B4B6B8",
                      marginLeft: open ? "16px" : "0",
                    }}
                    primary={"wallet"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    bgcolor: "#3f0097",
                    color: "#B4B6B8",
                    "&:hover": {
                      backgroundColor: "yellow",
                    },
                  }}
                >
                  <ListItemText
                    onClick={() => setSelectedtab(3)}
                    style={{
                      fontSize: "20px",
                      color: "#B4B6B8",
                      marginLeft: open ? "16px" : "0",
                    }}
                    primary={"Ewallet"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </AccordionDetails>
              </Accordion>
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {selectedtab === 0 && <Userdashboardmenu />}
        {selectedtab === 1 && <Userpage />}
        {selectedtab === 2 && <Addmoney />}
        {selectedtab === 3 && <Wallet />}
        {/* Add more tab content components as needed */}
      </Box>
    </Box>
  );
}

export default Dashboardmenubar;
