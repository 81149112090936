import React from "react";
import { Grid, TextField, Typography } from "@mui/material";

const Debitfund = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <h4>Deduct Fund in Wallet</h4>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 20,
          backgroundColor: "#fff",
          boxShadow: "2px 2px 5px 1px rgba(0, 0, 0, 0.5)",
          padding: "20px",
          paddingLeft: "70px",
          paddingRight: "70px",
        }}
      >
        <Grid
          container
          spacing={1}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid item xs={12} md={6}>
            <span>MemberID *</span>
          </Grid>
          <Grid item xs={12} md={6}>
            <div class="input-group mb-3">
              <select class="form-select" id="inputGroupSelect02">
                <option selected>Choose...</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid item xs={12} md={6}>
            <span>Amount*</span>
          </Grid>
          <Grid item xs={12} md={6}>
            <input
              style={{
                width: "100%",
                height: "47px",
                borderRadius: "10px",
                borderColor: "lightgray",
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid item xs={12} md={6}>
            <span>Narration *</span>
          </Grid>
          <Grid item xs={12} md={6}>
            <input
              style={{
                width: "100%",
                height: "120px",
                borderRadius: "10px",
                borderColor: "lightgray",
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid item xs={12} md={6}>
            <span>Secret Code/(Authentication Code) *</span>
          </Grid>
          <Grid item xs={12} md={6}>
            <input
              style={{
                width: "100%",
                height: "47px",
                borderRadius: "10px",
                borderColor: "lightgray",
              }}
            />
          </Grid>
        </Grid>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginLeft: "70px",
            gap: 30,
            // justifyContent: "center",
            // alignItems: "center",
          }}
        >
          <button
            style={{
              backgroundColor: "#F08080",
              padding: "5px",
              borderRadius: "15px",
              color: "#fff",
              fontSize: "14px",
              fontWeight: "bold",
              borderColor: "transparent",
              width: "70px",
            }}
          >
            Submit
          </button>
          <button
            style={{
              backgroundColor: "#F08080",
              padding: "5px",
              borderRadius: "15px",
              color: "#fff",
              fontSize: "14px",
              fontWeight: "bold",
              borderColor: "transparent",
              width: "70px",
            }}
          >
            Reset
          </button>
        </div>
      </div>
    </div>
  );
};

export default Debitfund;
