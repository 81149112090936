import { useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./AdminLogin.css";

function AdminLogin({ setAdminlogedin }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State to toggle login and MPIN UI
  const [mpin, setMpin] = useState(""); // State to store the MPIN value

  useEffect(() => {
    document.body.style.backgroundColor = "rgba(44, 202, 253, 1)";
    return () => {
      document.body.style.backgroundColor = "";
    };
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    const handleBackButton = (event) => {
      event.preventDefault();
      navigate("/");
    };
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [navigate]);

  const schema = Yup.object().shape({
    email: Yup.string()
      .required("Email is a required field")
      .email("Invalid email format"),
    password: Yup.string()
      .required("Password is a required field")
      .min(8, "Password must be at least 8 characters"),
  });

  const getData = async (values) => {
    try {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const payload = emailRegex.test(values.email)
        ? { email: values.email, password: values.password }
        : { email: "testuser@example.com", password: values.password };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/login`,
        payload
      );

      console.log(response, "response");
      const { token } = response.data;
      localStorage.setItem("adminAuthToken", token);

      alert("Admin login successfull");
      // toast.success("Login successful!");
      setAdminlogedin(true);
      setIsLoggedIn(true); // Hide login UI and show MPIN field
    } catch (error) {
      console.error(
        "Login failed with error: ",
        error.response ? error.response.data : error.message
      );
      // toast.error("Login failed. Please try again.");
      alert("Login failed. Please try again.");
    }
  };

  const adminToken = localStorage.getItem("adminAuthToken");
  const verifyMpin = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/verify-mpin`,
        { mpin },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${adminToken}`, // Pass the token in the Authorization header
          },
        }
      );

      // toast.success("MPIN verification successful!");

      alert("MPIN verification successful!");
      navigate("/AdminDashboard");
    } catch (error) {
      console.error(
        "MPIN verification failed: ",
        error.response ? error.response.data : error.message
      );
      // toast.error("MPIN verification failed. Please try again.");
      alert("MPIN verification failed. Please try again.");
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <ToastContainer />
      {!isLoggedIn ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            minHeight: "30vh",
          }}
        >
          <Formik
            validationSchema={schema}
            initialValues={{ email: "", password: "" }}
            onSubmit={(values, { setSubmitting }) => {
              getData(values)
                .then(() => {
                  setSubmitting(false); // Set submitting to false after the login request completes
                })
                .catch(() => {
                  setSubmitting(false); // Also set it to false if login request fails
                });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <div className="login">
                <div className="form">
                  <form noValidate onSubmit={handleSubmit}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <label style={{ fontSize: 14, fontWeight: "700" }}>
                        Email
                      </label>
                      <input
                        type="email"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        placeholder="Enter email id / username"
                        id="email"
                      />
                      <p className="error">
                        {errors.email && touched.email && errors.email}
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <label style={{ fontSize: 14, fontWeight: "700" }}>
                        Password
                      </label>
                      <input
                        type="password"
                        name="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        placeholder="Enter password"
                      />
                      <p className="error">
                        {errors.password && touched.password && errors.password}
                      </p>
                    </div>
                    <button
                      style={{
                        backgroundColor: "rgb(53, 132, 173)",
                        cursor: "pointer",
                        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
                      }}
                      type="submit"
                    >
                      Sign in
                    </button>
                  </form>
                </div>
              </div>
            )}
          </Formik>
        </div>
      ) : (
        // MPIN Verification UI
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            // marginTop
            minHeight: "100vh",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              // width: "50%",
            }}
          >
            <label style={{ fontSize: 14, fontWeight: "700", color: "#fff" }}>
              MPIN
            </label>

            <input
              style={{ padding: "10px  40px", borderRadius: "10px" }}
              type="text"
              name="mpin"
              value={mpin}
              onChange={(e) => setMpin(e.target.value)}
              placeholder="Enter your MPIN"
            />
          </div>
          <button
            style={{
              marginTop: "20px",
              backgroundColor: "rgb(53, 132, 173)",
              cursor: "pointer",
              padding: "10px 50px",
              borderRadius: "5px",
              color: "#fff",
            }}
            onClick={verifyMpin}
          >
            Verify
          </button>
        </div>
      )}
    </div>
  );
}

export default AdminLogin;
