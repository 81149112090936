// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Ensure the menu item text is hidden when the menu is collapsed */
@media (max-width: 600px) {
    .menu-item-text {
      display: none;
    }
  }
  
  /* Style adjustments for when the menu is expanded */
  .menu-expanded .menu-item-text {
    display: block;
  }
  `, "",{"version":3,"sources":["webpack://./src/screen/Menu.css"],"names":[],"mappings":"AAAA,mEAAmE;AACnE;IACI;MACE,aAAa;IACf;EACF;;EAEA,oDAAoD;EACpD;IACE,cAAc;EAChB","sourcesContent":["/* Ensure the menu item text is hidden when the menu is collapsed */\n@media (max-width: 600px) {\n    .menu-item-text {\n      display: none;\n    }\n  }\n  \n  /* Style adjustments for when the menu is expanded */\n  .menu-expanded .menu-item-text {\n    display: block;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
