import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useSelector, useDispatch } from "react-redux";
import { getAllData } from "../redux/Balance";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns"; // Import format function from date-fns
import Api from '../apiconfig/Api'

const columns = [
  { id: "serialNumber", label: "S.No", minWidth: 50 },
  { id: "id", label: "MemberID", minWidth: 100 },
  { id: "tranAmt", label: "Credit", minWidth: 100 },
  { id: "trandate", label: "Date", minWidth: 100, align: "center" },
  { id: "utr", label: "Narration", minWidth: 100 },
  { id: "balance", label: "Balance", minWidth: 100 },
  { id: "debit", label: "Debit", minWidth: 100 },
];

function Transactiontable() {
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Initialize the dates to the current date
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());

  const MemberID = localStorage.getItem("UserId");
  const MemberName = localStorage.getItem("Name");

  // Load data for the current date on component mount
  useEffect(() => {
    fetchCurrentDateData();
  }, []);

  const fetchCurrentDateData = async () => {
    setLoading(true);
    const token = localStorage.getItem("authToken");

    // Format the current date
    const formattedDate = format(new Date(), "MM-dd-yyyy");

    try {
      const response = await fetch(
        `${Api.GET_TRANSACTIONS_URL}?start_date=${formattedDate}&end_date=${formattedDate}`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setRows(Object.values(data));
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = async () => {
    if (!fromDate || !toDate) {
      alert("Please select both From date and To date");
      return;
    }

    setLoading(true);
    const token = localStorage.getItem("authToken");

    // Format the dates into MM-DD-YYYY format
    const formattedFromDate = format(fromDate, "MM-dd-yyyy");
    const formattedToDate = format(toDate, "MM-dd-yyyy");

    try {
      const response = await fetch(
        `${Api.GET_TRANSACTIONS_URL}?start_date=${formattedFromDate}&end_date=${formattedToDate}`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setRows(Object.values(data));
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      {/* Date Pickers and Search Button Row */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          padding: "10px",
          justifyContent: "center",
          gap: 30,
        }}
      >
        <DatePicker
          selected={fromDate}
          onChange={(date) => setFromDate(date)}
          dateFormat="yyyy/MM/dd"
          isClearable
          placeholderText="From date"
          customInput={<TextField label="From date" variant="outlined" />}
        />
        <DatePicker
          selected={toDate}
          onChange={(date) => setToDate(date)}
          dateFormat="yyyy/MM/dd"
          isClearable
          placeholderText="To date"
          customInput={<TextField label="To date" variant="outlined" />}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSearch}
          style={{ marginLeft: "10px", height: "56px" }}
        >
          Search
        </Button>
      </div>
      {/* Table */}
      <TableContainer sx={{ maxHeight: 440, marginTop: "30px" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
          {/* Initialize cumulativeBalance variable outside the map function to persist its value across rows */}
          {(() => {
            let cumulativeBalance = 0; // Initialize the cumulative balance
        
            return rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const credit = parseFloat(row.tranAmt) || 0;
                const debit = parseFloat(row.debit) || 0;
        
                // Update the cumulative balance for each row
                if (index === 0) {
                  cumulativeBalance = credit; // For the first row, set the balance as the credit value
                } else {
                  cumulativeBalance += credit; // For subsequent rows, add the credit to the cumulative balance
                }
        
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      let value;
        
                      if (column.id === "serialNumber") {
                        value = index + 1;
                      } else if (column.id === "balance") {
                        value = cumulativeBalance.toFixed(2); // Display the cumulative balance
                      } else if (column.id === "debit") {
                        value = debit.toFixed(2);
                      } else if (column.id === "tranAmt") {
                        value = credit.toFixed(2);
                      } else if (column.id === "id") {
                        value = MemberID; // Replace with MemberID
                      } else if (column.id === "MemberName") {
                        value = MemberName; // Replace with MemberName
                      } else {
                        value = row[column.id];
                      }
        
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              });
          })()}
        </TableBody>
        
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

export default Transactiontable;
